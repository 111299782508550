exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-employer-js": () => import("./../../../src/pages/employer.js" /* webpackChunkName: "component---src-pages-employer-js" */),
  "component---src-pages-forgottenpassword-js": () => import("./../../../src/pages/forgottenpassword.js" /* webpackChunkName: "component---src-pages-forgottenpassword-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-methodology-js": () => import("./../../../src/pages/methodology.js" /* webpackChunkName: "component---src-pages-methodology-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

