import React from 'react'
import GlobalContextProvider  from './src/context/context'




export const wrapRootElement = ({ element }) => {
  return (
    <GlobalContextProvider>{element}</GlobalContextProvider>      
  )
}

export const onInitialClientRender = () => {
  setTimeout(function() {
      document.getElementById("___loader").style.display = "none"
  }, 1000)
} 