import React, { Context, createContext, useReducer, useEffect } from "react";
import {parseForBeeSwarm} from "../functions/functions"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()
 
const initialState = {
	data: [0],
	current_employer: null,
	current_location: null,
	compare_year1: [2024,2023,2022,2021,2020,2019],
	compare_year2: [2024,2023,2022,2021,2020,2019],
	current_employer_type: "All Employers",
	current_location_type: "All Regions/Nations",
	current_size_type: "All Sizes",

	compare_year1_selected: 2024,
	compare_year2_selected: 2023, 
	compare_year1_show: false,
	compare_year2_show: false,	
	logged_in: false,
	show_employers:false,
	show_location: false, 
	show_size: false,

	select_options: null,
	detailed_data:null,
	username: null,
	password: null,
	token: null,
	loginError: null,
	size: ["All Sizes","Less than 250","250 to 499","500 to 999","1000 to 4999","5000 to 19,999","20,000 or more", "Not Provided"],
	locations: ["All Regions/Nations","East Midlands","East of England","London","N Ireland","North East","North West","Scotland","South East","South West","Wales","West Midlands","Yorkshire and The Humber"],
	sectors:["All Employers","Accommodation","Advertising","Architecture and Engineering","Archives and Museums",
	"Arts and Entertainment","Business Support","Cars and Vehicles","Chemicals","Clothing",
	"Construction","Defence,", "Education","Energy","Facilities","Farming","Finance and Insurance","Fishing",
	"Food and Drink","Foresty","Furniture","Gambling","Head Office Activities","Health (Human)","Health (Veterinary)",
	"Human Health Activities","Information Services","IT and Electronics","Law and Accountancy","Leather","Libraries","Logistics",
	"Machinery","Media","Membership Organisations","Metals","Minerals","Mining","Other",
	"Paper and Printing","Personal services","Pharmaceuticals","Postal","Property",
	"Public Administration","Remediation","Renting and leasing",
	"Repairs","Research and development","Residential care","Retail",
	"Retail Trade","Rubber","Security and Investigations",
	"Social Work","Sport","Staff and Recruitment", 
	"Telecoms","Textiles","Tobacco","Transport","Travel",
	"Waste","Water and sewerage","Wholesale","Wood"], 
}

function reducer(state, action){

	switch(action.type){
		case 'UPDATE_DATA': {
			return{
				...state,
				data: parseForBeeSwarm(action.payload)
			}
		}
		case 'UPDATE_USERNAME': {
			return{
				...state,
				username: action.payload,

			}
		}
		case 'UPDATE_LOCATION_TYPE': {
			return{
				...state,
				current_location_type: action.payload,
			}
		}
		case 'UPDATE_SIZE_TYPE': {
			return{
				...state,
				current_size_type: action.payload,
			}
		}		
		case 'UPDATE_EMPLOYER_TYPE': {
			return{
				...state,
				current_employer_type: action.payload,
			}
		}
		case 'UPDATE_YEAR1_SELECTION': {
			return{
				...state,
				compare_year1_selected: action.payload,
			}
		}	
		case 'UPDATE_YEAR2_SELECTION': {
			return{
				...state,
				compare_year2_selected: action.payload,
			}
		}
		case 'UPDATE_YEAR2_SHOW': {
			return{
				...state,
				compare_year2_show: action.payload,

			}
		}	
		case 'UPDATE_YEAR1_SHOW': {
			return{
				...state,
				compare_year1_show: action.payload,

			}
		}													
		case 'UPDATE_LOGIN_ERROR': {
			return{
				...state,
				loginError: action.payload,

			}
		}		
		case 'UPDATE_TOKEN': {
			return{
				...state,
				token: action.payload,


			}
		}		
		case 'UPDATE_PASSWORD': {
			return{
				...state,
				password:action.payload
			}
		}				
		case 'UPDATE_DETAILED_DATA': {
			return{
				...state,
				detailed_data: action.payload,
			}
		}			
		case 'UPDATE_CURRENT_EMPLOYER': {
			return{
				...state,
				current_employer: action.payload
			}
		}	
		case 'UPDATE_CURRENT_LOCATION': {
			return{
				...state,
				current_location: action.payload
			}
		}
		case 'UPDATE_COMPARE_YEAR1': {
			return{
				...state,
				compare_year1: action.payload
			}
		}
		case 'UPDATE_COMPARE_YEAR2': {
			return{
				...state,
				compare_year2: action.payload
			}
		}	
		case 'LOGGED_IN': {
			return{
				...state,
				logged_in: action.payload
			}
		}		
		case 'SHOW_EMPLOYERS': {
			return{
				...state,
				show_employers: action.payload
			}
		}	
		case 'SHOW_LOCATION': {
			return{
				...state,
				show_location: action.payload
			}
		}	
		case 'SHOW_SIZE': {
			return{
				...state,
				show_size: action.payload
			}
		}											
		default:
			throw new Error('Bad Action Type')
	}
}

const GlobalContextProvider = ({children}) => {

	const [state, dispatch] = React.useReducer(reducer, initialState, (state) => {



		const persistedToken = localStorage.getItem("AUTH_TOKEN")
		const token = persistedToken ? JSON.parse(persistedToken) : null

		const persistedLoggedIn = localStorage.getItem("LOGGED_IN")
		const logged_in = persistedLoggedIn ? JSON.parse(persistedLoggedIn) : 0

        return { ...state,  token, logged_in}
	})


	useEffect(() => {
        localStorage.setItem("AUTH_TOKEN", JSON.stringify(state.token))
    }, [state.token])
	useEffect(() => {
        localStorage.setItem("LOGGED_IN", JSON.stringify(state.logged_in))
    }, [state.logged_in])

	return (
	<GlobalStateContext.Provider value={state}>
		<GlobalDispatchContext.Provider value={dispatch}>
			{children}
		</GlobalDispatchContext.Provider>
	</GlobalStateContext.Provider>
	);
}

export default GlobalContextProvider