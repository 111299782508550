import { AccurateBeeswarm } from 'accurate-beeswarm-plot';
import React, { Context, createContext, useReducer, useEffect, Component } from "react";

const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);


export function filterPlainArray(array, filters) {
    const filterKeys = Object.keys(filters);
    return array.filter(item => {
      return filterKeys.every(key => {
        if (!filters[key].length) return true;
      return filters[key].find(filter => getValue(item[key]).includes(getValue(filter)));
      });
    });
  }


  export const parseForBeeSwarm = (data) =>{
	
	let radius = 1;
	let fn = d => d.value;
	let result = new AccurateBeeswarm(data, radius, fn)
	  .calculateYPositions();


	  result.forEach(function(item){
		  var name = item.datum.name;
		  var score = item.datum.score;

		  name = name.replace("&#8217;","'");

		  name = name.replace("&#038;","&");
		   

		item["name"] = name.toUpperCase(); 
		item["score"] = parseFloat(score).toFixed(1); 
		item["rank"] = item.datum.rank; 
		item["region"] = item.datum.region; 
		item["sector"] = item.datum.sector; 
		item["employerID"] = item.datum.employerID;
		item["key"] = item.datum.employerID;
		item["value"] = name.toUpperCase(); 
		item["size"] = item.datum.size; 

	});

	result.sort(function(a, b) {
		return a["rank"] - b["rank"];
	}); 
	  return result;
}


export  const getSize = (state) =>{

	if (state != null) {
	if (state.data != null){
	return state.data.length
	}
	}
}
export  const renderSimple = (key, state) =>{

	if (state != null) {

		if(state!= null && state.current_employer != null ){
			return state.current_employer[key];
		}
	}
  }

  export const renderDetailed = (key,year,state) =>{
	if (state != null) {
	if(year == null){
		year = "2024";

	}
	if(state!= null && state.detailed_data){
		if (state.detailed_data[year] != null){
		if (state.detailed_data[year][key] != null){

		return state.detailed_data[year][key];		
		}
	}
	}}
  }


  export const renderDifferenceFixed = (number1, number2) => {
	  var direction = "down";
	  if (number1 < number2){
		  direction = "up";

	  }
	  var calc = number1 - number2;
	  if (calc > 0){
		  calc = "+" + calc;

	  }
	  return (
		  <>
		  <i class={direction}></i>
		  <span>{calc}</span>
		  </>
		  )
  }

  export const renderDifferencePercentage = (number1, number2) => {
	  var direction = "down";
	  if (number1 < number2){
		  direction = "up";

	  }
	  return (
		  <>
		  	<i class={direction}></i>
		  	<span>{number1 - number2}</span>
		  </>
		  )
	}	